import * as dpadx from "../jslib/dpadx";
import {querySelectorAll, querySelectorRequired} from "jslib/utils";
import {startBrowserMetrics} from "./metrics";
import * as utils from "./utils";

declare const deviceInfo: {
    readonly bodyClass: string;
    setup(dpadxlib: typeof dpadx, deviceInfoAvailablePrefix?: string): void;
}

/**
 * Handler to be called when entering a generic ITG page.
 */
export function onITGPage() {
    window.addEventListener("mousewheel", onScroll);
    window.addEventListener("DOMMouseScroll", onScroll);

    // allow any click (right/middle) to follow link (important, as holding a
    for (const linkElement of querySelectorAll("a") as [HTMLLinkElement]) {
        linkElement.addEventListener("touchstop", onPressLink);
        linkElement.addEventListener("mouseup", onPressLink);

        // stop dragging on a link moving the link as a "ghost" see CORE-794
        linkElement.addEventListener("dragstart", onDragStart);
    }

    // right click -- has to be handled specifically to avoid double-submission
    for (const buttonElement of querySelectorAll("button, input[type=submit]") as [HTMLElement]) {
        buttonElement.addEventListener("touchstop", onButtonPress);
        buttonElement.addEventListener("mouseup", onButtonPress);
    }


    const modalClass = "modal_open";
    const allModalsHidden = `body:not(.${deviceInfo.bodyClass}):not(.${modalClass})`;
    const modalDialogShowing = `body.${modalClass}`;

    dpadx.setup();
    dpadx.addSelector(`${allModalsHidden} .patients .study`);
    dpadx.addSelector(`${allModalsHidden} .alphabet li`);
    dpadx.addSelector(`${allModalsHidden} a.button`);
    dpadx.addSelector(`${allModalsHidden} a.pagelink`);
    // modal dialogs
    dpadx.addSelector(`${modalDialogShowing} .modal-content button:not([disabled])`);

    // Set up device info
    deviceInfo.setup(dpadx, allModalsHidden);
}

/**
 * Handler to be called when entering the "patient selection" page.
 */
export function onSelectPage() {
    for (const studyElement of querySelectorAll(".study") as [HTMLElement]) {
        studyElement.addEventListener("touchstop", onStudyClick);
        studyElement.addEventListener("mouseup", onStudyClick);
        studyElement.addEventListener("click", onStudyClick);
    }

    window.addEventListener("beforeunload", splashPleaseWait);

    function onListElementClick(e: MouseEvent) {
        const location = this.children[0].getAttribute("href");
        if (location) {
            document.location = location;
        }
        e.preventDefault();
    }

    // make list items work as hyperlink within (so that the entire li is a button, not just the text)
    for (const listElement of querySelectorAll(".alphabet li") as [HTMLElement]) {
        listElement.addEventListener("touchstop", onListElementClick);
        listElement.addEventListener("mouseup", onListElementClick);
        listElement.addEventListener("click", onListElementClick);
    }

    startBrowserMetrics();

}

/**
 * Handler to be called when entering the "ITG Connect" page.
 */
export function onConnectPage() {
    // Redirect to the selection screen if this a first connection or
    // the splash screen. In all other cases, just reload the current
    // URL (to handle the case where this page is used as a fallback).
    const targetUrl = "/itg-handover/select";

    const pingUrl = "/static/ok.png";
    let attempts = 0;
    const maxAttempts = 30;
    let diagnosticMode = false;

    function showHideVideoPanel() {
        const panel = querySelectorRequired("#videopanel", HTMLElement);
        const preview = querySelectorRequired("#preview", HTMLVideoElement);

        if (panel.style.display === "block") {
            panel.style.display = "none";
            preview.src = "";
        } else {
            panel.style.display = "block";
            navigator.mediaDevices.getUserMedia({
                audio: false,
                video: {
                    width: 1280,
                    height: 1024,
                },
            }).then((stream: MediaStream) => {
                preview.srcObject = stream;
                preview.play();
            }).catch((err: any) => {
                querySelectorRequired("#videomessage", HTMLElement).innerHTML =
                `<div>${err.name}</div><div>${err.message}</div><div>${err.constraintName}</div>`;
            });
        }
    }

    document.onkeypress = event => {
        const pressedChar = String.fromCharCode(event.charCode);

        if (pressedChar === " ") {
            ping(true);
        } else if (!diagnosticMode) {
            if (pressedChar === "d") {
                querySelectorRequired("#status", HTMLElement).innerHTML = "";
                querySelectorRequired("#message", HTMLElement).innerHTML =
                    `Diagnostic Mode (<a id="connect_link" href="#">Connect</a>)`;
                diagnosticMode = true;
            }
        } else {
            switch (pressedChar) {
                case "v":
                    showHideVideoPanel();
                    break;
                case "d":
                    document.location.reload();
                    break;
            }
        }
    };

    /**
     * Displays an error message relating to a network error.
     */
    function showErrorMessage() {
        // Assume we"re not going to recover; if the user wants to
        // try again, connect as default rather than preserving the
        // URL (as a substantial time is likely to have elapsed).
        querySelectorRequired("#error_summary", HTMLElement).innerHTML = "Network not responding";
        querySelectorRequired("#error_detail", HTMLElement).innerHTML = "Contact IT to investigate the problem";
        querySelectorRequired("#error_banner", HTMLElement).className = "fadein";
    }

    /**
     * Sends a "ping" by loading a static resource with a random query string (prevents cache).
     * @param isOneOff whether the `ping` call is a one-off (not to be repeated).
     */
    function ping(isOneOff: boolean) {
        if (diagnosticMode && !isOneOff) {
            // Diagnostic mode cancels pings
            return;
        }

        const img = new Image();

        // Update status message
        querySelectorRequired("#status", HTMLElement).innerHTML = "Trying to connect<br />" + (".".repeat(attempts++ % 4));

        img.onload = () => {
            // Report success (there whilst page is loading)
            querySelectorRequired("#status", HTMLElement).innerHTML = "Connected";
            window.location.href = targetUrl;
        };

        img.onerror = () => {
            if (attempts >= maxAttempts) {
                showErrorMessage();
            }
            // Try again after a second
            if (!isOneOff) {
                setTimeout(ping, 1000);
            }
        };

        // Attempt to GET from the ping URL
        img.src = pingUrl + "?" + Math.random().toString().slice(2);
    }

    // Try an initial ping after 1s (to give a chance to press "d")
    setTimeout(ping, 1000);
}

/**
 * Handler for a study listing click event.
 * @param event - underlying MouseEvent
 */
function onStudyClick(event: MouseEvent) {
    document.location = this.getAttribute("data-link");
    event.preventDefault();
}

/**
 * Handler for a page scroll event.
 */
function onScroll() {
    const navElement = document.getElementsByTagName("nav")[0];
    const deviceInfoElement = querySelectorRequired("#device-info", HTMLElement);
    if ((document as any).scrollTop > 30) {
        utils.addClass(navElement, "compact");
        utils.addClass(deviceInfoElement, "compact");
        return;
    }
    utils.removeClass(navElement, "compact");
    utils.removeClass(deviceInfoElement, "compact");
}

/**
 * Handler for a psuedo-hyperlink click event.
 * @param event - underlying Event
 */
function onPressLink(event: MouseEvent) {
    // CORE-880:
    // single click already navigates. e.PreventDefault does not
    // prevent single click from navigating on FF so a double
    // request is made unless an exception is made via the
    // following if statement
    if (this.getAttribute("href") && event.which !== 1) {
        event.preventDefault();
        document.location = this.getAttribute("href");
    }
}

/**
 * Handler for a button click event.
 * @param event - underlying MouseEvent
 */
function onButtonPress(event: MouseEvent) {
    if (event.which === 3) {
        event.preventDefault();
        this.click();
    }
}

/**
 * Handler for "dragging" the page (for touchscreen devices).
 */
function onDragStart() {
    return false;
}

let ITGTimeoutTimer: ReturnType<typeof setTimeout>;

/**
 * Starts the "return to ITG Splash" timer (one minute).
 */
export function startTimeoutTimer() {
    document.addEventListener("keydown", resetTimeout);
    document.addEventListener("touchstart", resetTimeout);
    document.addEventListener("click", resetTimeout);
    document.addEventListener("beforeunload", resetTimeout);
    document.addEventListener("unload", resetTimeout);
    // initialise timer
    resetTimeout();
}

/**
 * Stops the "return to ITG Splash" timer (one minute).
 * Will be used in the splash page to prevent needless redirects.
 */
export function stopTimeoutTimer() {
    clearTimeout(ITGTimeoutTimer);
}

function resetTimeout() {
    clearTimeout(ITGTimeoutTimer);
    ITGTimeoutTimer = setTimeout(() => {
        const loadingModal = document.querySelector("#loading") as HTMLElement;
        if (!loadingModal || loadingModal.style.display !== "block") {
            window.location.href = "/itg-handover/splash";
        }
    }, 60e3);
}

export function splashPleaseWait() {
    // Note -- was used on the spash page, but now just used on the selection page.
    // Note -- h2 wrapper removed as it made the message jump.
    querySelectorRequired("#instruction", HTMLElement).innerHTML = "Please wait...";
}

import * as utils from "./utils";

let startedMetrics = false;

/**
 * Handler to be called when entering a page that should emit browser metric events.
 */
export function startBrowserMetrics() {
    if (!startedMetrics) {
        // Periodic logging of metrics, once every 30 seconds (lower interval for compatibility with testing)
        setInterval(heartbeat, 30e3);
        startedMetrics = true;
    }
}

/*
* `heartbeat` takes a logging function and window object, and returns
* a function which, on each call, collects browser metrics and logs them
* using the logging function.
*
* @param {function} log - the logging funciton
* @param {object} windowArg - (optional) the window object, (or a mock-up version for unit testing)
* @return {function} - function which when called, takes a snapshot of browser metrics and
*      logs them with the log function
*/
function heartbeat() {
    const metrics: any = {
        appVersion: window.navigator.appVersion,
    };
    const performance = (window.performance as any);
    if (performance.memory) {
        /* tslint:disable:no-string-literal */  // a legacy artefact
        metrics["jsHeapSizeLimit"] = performance.memory.jsHeapSizeLimit;
        metrics["totalJSHeapSize"] = performance.memory.totalJSHeapSize;
        metrics["usedJSHeapSize"] = performance.memory.usedJSHeapSize;
    }
    if (window.screen) {
        metrics["screenWidth"] = window.screen.width;
        metrics["screenHeight"] = window.screen.height;
        metrics["screenAvailWidth"] = window.screen.availWidth;
        metrics["screenAvailHeight"] = window.screen.availHeight;
    }
    /* tslint:enable:no-string-literal */

    utils.log("browser_metrics", metrics);
}
/**
 * Library to allow control of an arbitrary HTML interface using a d-pad remote
 */

import * as dpadxlib from "./dpadxlib";

// the class to apply that indicates the element to be selected
const ELEMENT_SELECTED_CLASS = "dpadTargeted";

// should select candidate elements that are *logically* selectable -- i.e. not
// disabled.
// Visibility is handled separately by the engine.
// Make sure these selectors have corresponding CSS when
// dpadTargeted class is applied, else invisible selection will
// occur and confuse users
const element_selectors: string[] = [];

const INACTIVITY_TIMEOUT = 20 * 1000;

let targetedElement: Element | null = null;
let cursorElement: HTMLElement | null = null;

let watchdog: Watchdog | null = null;

// co-ordinate used when there is no targeted element. This can happen:
// 1. In initial state
// 2. When an element is removed
// 3. When an element is disabled TODO: when element is disabled, untarget
// 4. When the watchdog untargets
// The co-ordinate is either the center of the screen or the last known target
// location subject to watchdog fall-back.
let startCoordinate: number[] = [0,0];

function isVisible(e: Element): boolean {
    const rect = e.getBoundingClientRect();
    return (e.getAttribute("disabled") === null &&
            window.getComputedStyle(e).display !== "none" &&
            rect.top < window.innerHeight &&
            rect.left < window.innerWidth &&
            rect.bottom > 0 &&
            rect.left > 0);
}

function findAvailableElements() {
    const availableElements: Element[] = [];

    document.querySelectorAll(element_selectors.join(", ")).forEach(
        (el: Element) => {
            // assess visibility
            if (isVisible(el)) {
                availableElements.push(el);
            }
        }
    );

    return availableElements;
}

function getBBoxCenter(e: Element): number[] {
    return [
        e.getBoundingClientRect().left/2 + e.getBoundingClientRect().right/2,
        e.getBoundingClientRect().top/2 + e.getBoundingClientRect().bottom/2,
    ];
}

function target(e: Element, { animate = true } = {}) {
    unTarget();
    e.classList.add(ELEMENT_SELECTED_CLASS);
    startCoordinate = getBBoxCenter(e);
    targetedElement = e;

    if (cursorElement !== null) {
        if (animate) {
            // The movement is handled by the CSS transition
            cursorElement.animate([
                {"opacity": 1},
                {"opacity": 1},
                {"opacity": 1},
                {"opacity": 1},
                {"opacity": 0},
            ], 300);
        }
        cursorElement.style.left = startCoordinate[0] + "px";
        cursorElement.style.top = startCoordinate[1] + "px";
    }
}

function unTarget() {
    if (targetedElement !== null) {
        targetedElement.classList.remove(ELEMENT_SELECTED_CLASS);
        targetedElement = null;
    }
}

// untarget and reset last position
export function reset() {
    unTarget();

    if (cursorElement != null) {
        cursorElement.style.opacity = "0";
        cursorElement.style.left = "50%";
        cursorElement.style.top = "50%";
    }

    // re-set to center of screen
    startCoordinate = [window.innerWidth/2, window.innerHeight/2];
}

// focus the next UI button in the given direction
function targetNext(direction: dpadxlib.Direction) {
    if (watchdog !== null) { watchdog.feed(); }

    // recompute availble elements -- new handles may have arrived or UI may have changed mode
    const availableElements = findAvailableElements();

    // no longer available as was removed from DOM or no longer target-able (disabled)
    const initialTarget = targetedElement;
    if (targetedElement !== null && !availableElements.includes(targetedElement)) {
        unTarget();
    }

    // Try targeting based on data attributes
    if (
        initialTarget && targetNextFromDataAttr(
            initialTarget as HTMLElement, direction, availableElements)
    ) {
        return;
    }

    // find the closest element
    let closestDistance = 10000;
    let closestElement: Element | null = null;
    for (let i=0; i < availableElements.length; i++) {
        const givenElement = availableElements[i];

        const distance = dpadxlib.distanceInDirection(
            direction,
            startCoordinate,
            getBBoxCenter(givenElement),
        );

        if (
            distance < closestDistance &&
            distance !== dpadxlib.UNTARGETABLE &&
            givenElement !== targetedElement
        ) {
            closestDistance = distance;
            closestElement = givenElement;
        }
    }

    // edge case -- no element currently targeted and there is nothing in the
    // direction that the user selected. In this case, choose an arbitrary target
    if (closestElement === null && targetedElement === null && availableElements.length > 0) {
        closestElement = availableElements[0];
    }

    // focus the new element and index if there is one. Else leave the targeted
    // element unchanged.
    if (closestElement !== null) {
        target(closestElement);
    }
}

/**
 * Attempt to set the next target based on the current target and the direction,
 * using information stored in the data-dpadx-{direction}-selector data
 * attribute. Return true if successful, else false.
 */
function targetNextFromDataAttr(
    initialTarget: HTMLElement,
    direction: dpadxlib.Direction,
    availableElements = findAvailableElements(),
): boolean {
    const dataAttr = `dpadx${capitalize(direction)}Selector`;
    const nextSelector = (initialTarget as HTMLElement).dataset[dataAttr];
    if (nextSelector) {
        const nextElement: HTMLElement|null = document.querySelector(nextSelector);
        if (nextElement && availableElements.includes(nextElement)) {
            target(nextElement);
            return true;
        }
    }
    return false;
}

function capitalize(s: string) {
    return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
}

// Click the currently targeted UI button if any
function click() {
    if (watchdog !== null) { watchdog.feed(); }

    if (targetedElement === null) { return; }

    if (typeof (targetedElement as HTMLElement).click === "function") {
        // works for radio input labels, unlike below
        (targetedElement as HTMLElement).click();
    } else {
        // works for SVGs unlike above
        targetedElement.dispatchEvent(new Event("click"));
    }
}

class Watchdog {
    private handle: ReturnType<typeof setTimeout> | undefined;
    private callback: () => void;
    private timeout: number;
    public constructor(callback: () => void, timeout: number) {
        this.callback = callback;
        this.timeout = timeout;
        this.handle = setTimeout(this.callback, this.timeout);
    }

    public feed() {
        if (this.handle !== undefined) {
            clearTimeout(this.handle);
            this.handle = undefined;
        }
        this.handle = setTimeout(this.callback, this.timeout);
    }
}

// assess targetedElement, untargeting if no longer visible
function untargetInvisibleElement() {
    if (targetedElement === null) {
        return;
    }

    if (!isVisible(targetedElement)) {
        unTarget();
    }
}

export function setup({ inactivity_timeout=INACTIVITY_TIMEOUT }: { inactivity_timeout?: number } = {}) {
    // set to center of screen
    startCoordinate = [window.innerWidth/2, window.innerHeight/2];

    // watchdog to untarget after a timeout
    watchdog = new Watchdog(() => reset(), inactivity_timeout);

    // untarget if element disappears (without resetting position)
    setInterval(() => untargetInvisibleElement(), 100);

    cursorElement = document.createElement('div');
    cursorElement.id = "dpadCursor";
    // set mechanical css values
    cursorElement.style.pointerEvents = "none";  // hould not interfere with clicking buttons
    cursorElement.style.transition = "top 300ms, left 300ms";
    cursorElement.style.position = "fixed";
    cursorElement.style.opacity = "0";
    cursorElement.style.left = "50%";
    cursorElement.style.top = "50%";
    document.body.appendChild(cursorElement);

    document.addEventListener("keydown", function(e: KeyboardEvent) {
        // this handler must not interfere with regular bindings when dpad is
        // not activated.
        switch (e.keyCode) {
            // button 13: return
            case 13:
                if (targetedElement !== null) {
                    if (watchdog !== null) { watchdog.feed(); }
                    // ignore repeat keypresses for return which could have side-affects
                    if (!e.repeat) {
                        click();
                    }
                    // return key may have a side affect like pressing the
                    // focussed button. Prevent that.
                    e.preventDefault();
                }
                break;
            case 38:
                // allow repeat for directions
                targetNext("UP");
                if (watchdog !== null) { watchdog.feed(); }
                e.preventDefault();
                break;
            case 40:
                targetNext("DOWN");
                if (watchdog !== null) { watchdog.feed(); }
                e.preventDefault();
                break;
            case 37:
                targetNext("LEFT");
                if (watchdog !== null) { watchdog.feed(); }
                e.preventDefault();
                break;
            case 39:
                targetNext("RIGHT");
                if (watchdog !== null) { watchdog.feed(); }
                e.preventDefault();
                break;
            default:
                return;
        }
    });

    // Required to fix CORE-2547 in firefox
    // Otherwise, clicking on a button gives that button focus, and it
    // swallows all keypress events before they reach dpadx
    document.addEventListener("click", () => {
        if (
            document.activeElement instanceof HTMLInputElement
            && document.activeElement.type !== "submit"
        ) {
            return;
        }
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    });
}

export function addSelector(selector: string) {
    element_selectors.push(selector);
}

export function isTargeted(el: HTMLElement|EventTarget): boolean {
    return el === getTarget();
}

/**
 * Error raised when an invalid target element is requested
 */
class InvalidTargetError extends Error {

    name = "InvalidTargetError";

    constructor(private _element: HTMLElement) {
        super(`Invalid target: ${_element.toString()}`);
        Object.setPrototypeOf(this, InvalidTargetError.prototype);
    }

    get element(): HTMLElement {
        return this._element;
    }
}

export function setTarget(element: HTMLElement, { animate = true } = {}) {
    if (!findAvailableElements().includes(element)) {
        throw new InvalidTargetError(element);
    }
    target(element, { animate });
}

export function getTarget() {
    return targetedElement;
}

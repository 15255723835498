import * as utils from "./utils";
import { querySelectorRequired } from "jslib/utils";

export function showNotifications() {
    const notificationBox = querySelectorRequired("#notification-container", HTMLElement);
	notificationBox.classList.toggle("show");
}

export function removeNotification(removeNotificationURL : any, redirectURL :any) {
	const csrftoken = utils.getCookie("csrftoken");
	const xmlhttp = new XMLHttpRequest();

	xmlhttp.onreadystatechange = function() {
		if (xmlhttp.readyState === XMLHttpRequest.DONE) {
			if (xmlhttp.status === 200) {
				window.location.replace(redirectURL);
			} else {
				alert('There was an error');
			}
		}
	};

	xmlhttp.open("GET", removeNotificationURL, true);
	xmlhttp.setRequestHeader("X-CSRFToken", csrftoken!);
	xmlhttp.send();
}

export function removeElement(el: any) {
    el.parentNode.parentNode.style.display='none';
}

export function messageAsLink(link:string){
	window.location.href = link;
}

function onMouseClick(e: MouseEvent) {
	const target = e.target as Element;
	if (!target.classList.contains('notification-icon') && !target.classList.contains('notification-count')) {
		const notificationBox = querySelectorRequired("#notification-container", HTMLElement);
		const isClickInsideElement = notificationBox.contains(target)

		if(!isClickInsideElement){
			notificationBox.classList.remove('show');
		}
	}
}

export function notificationsSetup() {
	window.addEventListener('click', onMouseClick)

	const dropdownParents = document.querySelectorAll<HTMLElement>(".dropdown-item-parent");
	for (const element of dropdownParents) {
		const key = "href"
		const href = element.dataset[key];
		if (href === undefined) {
			console.error("dropdown-item-parent had undefined data-href attribute");
			continue;
		}
		element.onclick = function() {
			window.location.href = href;
		}
	}
}

/*
*   Main entrypoint for the vault JS library.
*
*   All "components" to be included in the library must be listed as an export as per below.
*/

import {querySelectorRequired} from "jslib/utils";

import * as diagnostics from "./diagnostics";
import * as itg from "./itg";
import * as metrics from "./metrics";
import * as pacs from "./pacs";
import * as scanmanager from "./scanmanager";
import * as utils from "./utils";
import * as notifications from "./notifications"

export {
    diagnostics,
    itg,
    metrics,
    pacs,
    scanmanager,
    utils,
    notifications,
};

const settings = {
    contactEmail: utils.getScriptData("contactEmail"),
    contactName: utils.getScriptData("contactName"),
    isAuthenticated: utils.getScriptData("isAuthenticated"),
    isGuicloudPage: utils.getScriptData("isGuicloudPage") === "true",
    isITGPage: utils.getScriptData("isITGPage") === "true",
    singleUse: utils.getScriptData("singleUse"),
};

// bootstrap page
document.addEventListener("DOMContentLoaded", () => {

    if (settings.isITGPage) {
        itg.startTimeoutTimer();
    } else if (!settings.isGuicloudPage){
        utils.startInactivityTimeoutTimer();
    }

    if (settings.singleUse) {
        // no right click to escape
        document.body.addEventListener("contextmenu", e => {
            e.preventDefault();
        });
    }
    // enables responsive behavior serverside when paginating
    document.cookie = "width=" + window.innerWidth + ";path=/";

    if (settings.isAuthenticated) {
        const contactName = querySelectorRequired(`#device-info input[name="contact_name"]`, HTMLInputElement);
        const contactEmail = querySelectorRequired(`#device-info input[name="contact_info"]`, HTMLInputElement);
        contactName.value = settings.contactName;
        contactEmail.value = settings.contactEmail;
    }
});
